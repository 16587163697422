<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { waterTestingSingleDetails } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.getSingleData()
  },
  data () {
    return {
      waterTestingRequest: {},
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name_bn, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name_bn },
          { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name_bn, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name_bn },
          // { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village_bn, key1: this.$t('globalTrans.from_date'), val1: this.$d(new Date(this.waterTestingRequest.from_date)) },
          // { key: this.$t('farmerOperator.sample_number'), val: this.$n(this.waterTestingRequest.sample_number, { useGrouping: false }), key1: this.$t('globalTrans.to_date'), val1: this.$d(new Date(this.waterTestingRequest.to_date)) },
          { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village_bn, key1: this.$t('globalTrans.from_date'), val1: this.waterTestingRequest.from_date },
          { key: this.$t('farmerOperator.sample_number'), val: this.$n(this.waterTestingRequest.sample_number, { useGrouping: false }), key1: this.$t('globalTrans.to_date'), val1: this.waterTestingRequest.to_date },
          { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name_bn, key1: this.$t('farmerOperator.sample_serial'), val1: this.$n(this.waterTestingRequest.sample_serial, { useGrouping: false }) },
          { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name_bn, key1: this.$t('farmerOperator.farmer_id'), val1: this.$n(this.waterTestingRequest.farmer_id, { useGrouping: false }) },
          { key: this.$t('externalUserIrrigation.application_id'), val: this.waterTestingRequest.application_id }
        ]
      } else {
          return [
            { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name },
            { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name },
            { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name },
            { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village, key1: this.$t('globalTrans.from_date'), val1: this.waterTestingRequest.from_date },
            { key: this.$t('farmerOperator.sample_number'), val: this.waterTestingRequest.sample_number, key1: this.$t('globalTrans.to_date'), val1: this.waterTestingRequest.to_date },
            { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name, key1: this.$t('farmerOperator.sample_serial'), val1: this.waterTestingRequest.sample_serial },
            { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name, key1: this.$t('farmerOperator.farmer_id'), val1: this.waterTestingRequest.farmer_id },
            { key: this.$t('externalUserIrrigation.application_id'), val: this.waterTestingRequest.application_id }
          ]
      }
    }
  },
  methods: {
    getSingleData () {
        RestApi.getData(irriSchemeServiceBaseUrl, waterTestingSingleDetails + this.id).then(response => {
            this.waterTestingRequest = this.getRelationalData(response)
        })
    },
    getRelationalData (item) {
        const organizationList = this.$store.state.orgList
        const divisionList = this.$store.state.commonObj.divisionList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const unionList = this.$store.state.commonObj.unionList
        const testingTypeList = this.$store.state.commonObj.testingTypeList
        const waterTestingStatusList = this.$store.state.commonObj.waterTestingStatusList
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        let divisionObject = { text: '', text_bn: '' }
        let districtObject = { text: '', text_bn: '' }
        let upazilaObject = { text: '', text_bn: '' }
        let unionObject = { text: '', text_bn: '' }
        if (item.far_division_id) {
            divisionObject = divisionList.find(division => division.value === item.far_division_id)
            districtObject = districtList.find(district => district.value === item.far_district_id)
            upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
            unionObject = unionList.find(union => union.value === item.far_union_id)
        }
        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
            org_name: orgObject.text_en,
            org_name_bn: orgObject.text_bn
        }
        const divisionData = {
            division_name: divisionObject.text,
            division_name_bn: divisionObject.text_bn
        }
        const districtData = {
            district_name: districtObject.text,
            district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
            upazilla_name: upazilaObject.text,
            upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
            union_name: unionObject.text,
            union_name_bn: unionObject.text_bn
        }
        const TestingTypeData = {
            testing_type_name: testingTypeObject.name,
            testing_type_name_bn: testingTypeObject.name_bn
        }
        const waterTestingData = {
            testing_status_name: statusListObject.name,
            testing_status_name_bn: statusListObject.name_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, TestingTypeData, waterTestingData)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
