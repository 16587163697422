<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                      <h5 class='complain-title'>{{ $t('pump_install.application_details') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.application_id') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.application_id }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.nid') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.nid }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.father_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.father_name_bn : application.father_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ appHierarchyNames.divisionName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ appHierarchyNames.upazilaName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.village') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.far_village_bn : application.far_village }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.organization') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ getOrganization(application.org_id) }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.irrigation_type') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.scheme_type_name_bn : application.scheme_type_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.mother_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.mother_name_bn : application.mother_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ appHierarchyNames.districtName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ appHierarchyNames.unionName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.mobile') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.far_mobile_no | mobileNumber }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                    <h5 class='complain-title'>{{ $t('pump_install.scheme_man_details') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? application.sch_man_name_bn : application.sch_man_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.mobile') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.sch_man_mobile_no }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.father_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_father_name_bn : application.sch_man_father_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.divisionName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.upazilaName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.village') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_village_bn : application.sch_man_village }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.email') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.email }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.nid') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ application.sch_man_nid }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.mother_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.sch_man_mother_name_bn : application.sch_man_mother_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.districtName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.unionName }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col lg="12" sm="12" class="text-center mb-4">
                    <h5 class='complain-title'>{{ $t('pump_install.pump_details') }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.division') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.divisionName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.upazila') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.upazilaName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.mauza_no') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.plot_no') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_plot_no_bn : application.pump_plot_no }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.district') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.districtName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.union') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ schemeHierarchyNames.unionName }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.pump_jl_no') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale == 'bn') ? application.pump_mauza_no_bn : application.pump_mauza_no }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { applicationShow } from '../../api/routes'
import HierarchycalDropdownNames from '@/Utils/common'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getSchemeApplicationDetails()
      Object.freeze(tmp)
      this.application = tmp
    }
  },
  data () {
    return {
        loader: true,
        appHierarchyNames: null,
        pumpHierarchyNames: null,
        schemeHierarchyNames: null,
        application: '',
        survey_notes: [],
        base_url: irriSchemeServiceBaseUrl
    }
  },
  computed: {
  },
  methods: {
    async getSchemeApplicationDetails () {
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, `${applicationShow}/${this.$props.id}`)
      if (result.success) {
        this.application = result.data
        this.appHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.far_union_id, 'union')
        this.pumpHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.pump_union_id, 'union')
        this.schemeHierarchyNames = HierarchycalDropdownNames.getCommonHierarchyNames(this.application.sch_man_union_id, 'union')
      }
      this.loader = false
    },
    getOrganization (orgId) {
        const orgList = this.$store.state.orgList
        const org = orgList.find(orgItem => orgItem.value === orgId)
        if (this.$i18n.locale === 'bn') {
            return org.text_bn
        } else {
            return org.text_en
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
