<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-4">
                  <h5 class='complain-title'>{{ $t('externalUserIrrigation.pump_operator_application') + ' ' + $t('externalUserIrrigation.details') }}</h5>
                </b-col>
              </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark" style="font-weight:bold">{{ $t('org_pro.organization') }} :</p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? pumpOperatorApp.org_name_bn : pumpOperatorApp.org_name }}</p>
                      </b-col>
                    </b-row>
                    <!-- <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark" style="font-weight:bold" >{{ $t('externalUserIrrigation.pump') + ' ' + $t('user.name') }} :</p>
                      </b-col>
                      <b-col lg="9" sm="12">
                        <p class="text-dark"  v-html="(this.$i18n.locale === 'bn') ? pumpOperatorApp.pump_id : pumpOperatorApp.pump_id"></p>
                      </b-col>
                    </b-row> -->
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('user.namel') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.name_bn : pumpOperatorApp.name }}</b-td>
                              <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                              <b-td>{{ pumpOperatorApp.date_of_birth | dateFormat }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.father_name_bn : pumpOperatorApp.father_name }}</b-td>
                              <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.mother_name_bn : pumpOperatorApp.mother_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('org_pro_division.division') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.divion_name_bn : pumpOperatorApp.division_name }}</b-td>
                              <b-th>{{ $t('org_pro_district.district') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.district_name_bn : pumpOperatorApp.district_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.upazilla_name_bn : pumpOperatorApp.upazilla_name }}</b-td>
                              <b-th>{{ $t('org_pro_union.union') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.union_name_bn : pumpOperatorApp.union_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalUserIrrigation.village') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? pumpOperatorApp.far_village_bn : pumpOperatorApp.far_village }}</b-td>
                              <b-th>{{ $t('externalUserIrrigation.educational_qualification') }}</b-th>
                              <b-td>{{ pumpOperatorApp.qualification }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('user.phone_no') }}</b-th>
                              <b-td>{{ (this.$i18n.locale === 'bn')? $n('0' + pumpOperatorApp.far_mobile_no, { useGrouping: false }) : pumpOperatorApp.far_mobile_no }}</b-td>
                              <b-th>{{ $t('user.email') }}</b-th>
                              <b-td>{{ pumpOperatorApp.email }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('externalUserIrrigation.nid') }}</b-th>
                              <b-td colspan="3">{{ $n(pumpOperatorApp.nid, { useGrouping: false }) }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpOptApplicationSingleDetails } from '../../api/routes'

export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    this.getSingleData()
  },
  data () {
    return {
      pumpOperatorApp: {},
      slOffset: 1
    }
  },
  methods: {
    getSingleData () {
        RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationSingleDetails + this.id).then(response => {
            this.pumpOperatorApp = this.getRelationalData(response)
        })
    },
    getRelationalData (data) {
      const commonObj = this.$store.state.ExternalUserIrrigation.commonObj
      const orgList = commonObj.organizationProfileList
      const divisionList = commonObj.divisionList
      const districtList = commonObj.districtList
      const upazilaList = commonObj.upazilaList
      const unionList = commonObj.unionList

      const orgPro = orgList.find(org => org.value === data.org_id)
      const division = divisionList.find(division => division.value === data.far_division_id)
      const district = districtList.find(district => district.value === data.far_district_id)
      const upazilla = upazilaList.find(upazilla => upazilla.value === data.far_upazilla_id)
      const union = unionList.find(union => union.value === data.far_union_id)
      return Object.assign({}, data,
        { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
        { division_name: division.text_en, division_name_bn: division.text_bn },
        { district_name: district.text_en, district_name_bn: district.text_bn },
        { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn },
        { union_name: union.text_en, union_name_bn: union.text_bn }
      )
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
