<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('cardPayment.payment_collection_report') }}</h4>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.organization')"
                    label-for="org_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="organizationList"
                    id="org_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cardPayment.application_id')"
                        label-for="application_id"
                        >
                        <b-form-input
                            id="application_id"
                            v-model="search.application_id"
                            placeholder=""
                            >
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cardPayment.application_type')"
                        label-for="application_type_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.application_type_id"
                        :options="applicationTypes"
                        id="application_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.from_date')"
                    label-for="from_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.from_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.to_date')"
                    label-for="to_date"
                    >
                        <flat-pickr class="form-control"
                            v-model="search.to_date"
                            :placeholder="$t('globalTrans.select_date')"
                        ></flat-pickr>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
        </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('cardPayment.payment_collection_report') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button variant="primary" @click="pdfExport" class="mr-2">
                {{  $t('globalTrans.print') }}
                </b-button>
                <export-excel
                    class="btn btn-success mr-2"
                    :data="dataCustomize"
                    :fields= "json_fields"
                    :title="$t('cardPayment.payment_collection_report')"
                    worksheet="$t('cardPayment.payment_collection_report')"
                    name="filename.xls">
                    {{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="irriSchemeServiceBaseUrl" uri="report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                          {{ $t('cardPayment.payment_collection_report')}}
                        </list-report-head>
                    </b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="applicationReportList" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ data.item.application_id }}
                    </template>
                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:cell(name_bn)="data">
                      {{ data.item.name_bn }}
                    </template>
                    <template v-slot:cell(application_type_name)="data">
                      {{ data.item.application_type_name }}
                    </template>
                    <template v-slot:cell(created_at)="data">
                      {{ data.item.updated_at | dateFormat }}
                    </template>
                    <template v-slot:cell(application_type_name_bn)="data">
                      {{ data.item.application_type_name_bn }}
                    </template>
                    <template v-slot:cell(view)="data">
                      <b-button v-if="search.application_type_id === 1" class="mr-1" title="Scheme Detail"  variant=" iq-bg-success" size="sm" @click="schemeDetail(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-if="search.application_type_id === 2" class="mr-1" title="Pump Operator Detail"  variant=" iq-bg-success" size="sm" @click="pumpOperatorDetail(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-if="search.application_type_id === 3" class="mr-1" title="Smart Card Detail"  variant=" iq-bg-success" size="sm" @click="smartCardDetail(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-if="search.application_type_id === 4" class="mr-1" title="Water Testing Detail"  variant=" iq-bg-success" size="sm" @click="waterTestingDetail(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="pumpOperatorDetail" size="lg" :title="$t('cardPayment.application_view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <PumpOptApplicationDetails :id="appId"/>
    </b-modal>
    <b-modal id="schemeDetail" size="lg" :title="$t('cardPayment.application_view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <SchemeApplicationDetails :id="appId"/>
    </b-modal>
    <b-modal id="smartCardDetail" size="xl" :title="$t('cardPayment.application_view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <SmartCardShow :id="appId"/>
    </b-modal>
    <b-modal id="waterTestingDetail" size="lg" :title="$t('cardPayment.application_view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <WaterTestingDetailModal :id="appId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import PumpOptApplicationDetails from './PumpOptApplicationDetails'
import SchemeApplicationDetails from './SchemeApplicationDetails'
import SmartCardShow from './SmartCardShow'
import WaterTestingDetailModal from './WaterTestingDetailModal'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import { mapGetters } from 'vuex'
import excel from 'vue-excel-export'
import Vue from 'vue'
import ExportPdf from '@/Utils/export-pdf'
Vue.use(excel)

const excelColumn = {
  Organization: 'org',
  'Payment Type': 'pType',
  Amount: 'amnt',
  'Payment Date': 'pDate',
  'Applicant Name': 'aName'
}
const excelColumnBn = {
  সংগঠন: 'org',
  'পরিশোধের ধরণ': 'pType',
  পরিমাণ: 'amnt',
  'টাকা প্রদানের তারিখ': 'pDate',
  'আবেদনকারীর নাম': 'aName'
}
export default {
  name: 'UiDataTable',
  components: {
    PumpOptApplicationDetails,
    SchemeApplicationDetails,
    SmartCardShow,
    WaterTestingDetailModal,
    ListReportHead
  },
  data () {
    return {
      defaultReissueVal: '',
      showData: false,
      showHeading: false,
      search: {
        org_id: 0,
        application_id: '',
        application_type_id: 0,
        from_date: '',
        to_date: ''
      },
      testId: 0,
      organization: {},
      applicationReportList: [],
      irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
      appId: ''
    }
    },
    created () {
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const applicationReportList = this.applicationReportList
            applicationReportList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    item.org = item.org_name_bn
                    item.pType = item.payment_type_bn
                    item.amnt = item.amount
                    item.pDate = item.created_at
                    item.aName = item.far_name_bn
                } else {
                    item.org = item.org_name
                    item.pType = item.payment_type
                    item.amnt = item.amount
                    item.pDate = item.created_at
                    item.aName = item.far_name
                }
                return Object.assign({}, item)
            })
            return applicationReportList
        },
        organizationList: function () {
            const listObject = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return listObject
        },
        applicationTypes: function () {
            const objectData = this.$store.state.commonObj.paymentApplicationTypeList
            return objectData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.id, text: item.name_bn }
                } else {
                return { value: item.id, text: item.name }
                }
            })
        },
        loading () {
            return this.$store.state.commonObj.loading
        },
        listReload () {
            return this.$store.state.commonObj.listReload
        },
        loadingState () {
            if (this.listReload) {
                return true
            } else if (this.loading) {
                return true
            } else {
                return false
            }
        },
        currentLocale () {
            return this.$i18n.locale
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.organization'), class: 'text-left' },
                { label: this.$t('cardPayment.payment_type'), class: 'text-left' },
                { label: this.$t('cardPayment.amount'), class: 'text-left' },
                { label: this.$t('cardPayment.payment_date'), class: 'text-left' },
                { label: this.$t('cardPayment.applicant_name'), class: 'text-left' },
                { label: this.$t('cardPayment.application_view'), class: 'text-left' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'org_name_bn' },
                { key: 'payment_type_bn' },
                { key: 'amount' },
                { key: 'created_at' },
                { key: 'far_name_bn' },
                { key: 'view' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'payment_type' },
                { key: 'amount' },
                { key: 'created_at' },
                { key: 'far_name' },
                { key: 'view' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    watch: {
        loadingState: function (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    mounted () {
        core.index()
        if (this.authUser.role_id === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: 0
            })
        } else {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id
            })
        }
    },
    methods: {
        schemeDetail (item) {
            this.appId = item.far_application_id
            this.$bvModal.show('schemeDetail')
        },
        pumpOperatorDetail (item) {
            this.appId = item.far_application_id
            this.$bvModal.show('pumpOperatorDetail')
        },
        smartCardDetail (item) {
            this.appId = item.far_application_id
            this.$bvModal.show('smartCardDetail')
        },
        waterTestingDetail (item) {
            this.appId = item.far_application_id
            this.$bvModal.show('waterTestingDetail')
        },
        searchData () {
            if (this.search.org_id !== 0) {
                this.loadData()
            }
        },
        details (item) {
            this.testId = item.id
        },
        loadData () {
            this.$store.commit('mutateCommonProperties', { loading: true })
            RestApi.getData(irriSchemeServiceBaseUrl, 'payment-germplasm-collection/report', this.search).then(response => {
                if (response.success) {
                    this.dataList(response)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataList (data) {
            if (data.data.length !== 0) {
                this.showData = true
            }
            const organizationList = this.$store.state.commonObj.organizationProfileList
            const pumpOperatorData = data.data.map(item => {
                let orgObject = {}
                if (item.org_id !== null) {
                    orgObject = organizationList.find(organization => organization.value === item.org_id)
                }
                const orgData = {
                    org_name: orgObject.text,
                    org_name_bn: orgObject.text_bn
                }
                var storeItemList = false
                // Scheme
                if (item.application_type === 1) {
                    storeItemList = this.$store.state.commonObj.schemePaymentList
                }
                // Pump Operation
                if (item.application_type === 2) {
                    storeItemList = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
                }
                // Smart Card
                if (item.application_type === 3) {
                    storeItemList = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
                }
                // Water Testing
                if (item.application_type === 4) {
                    storeItemList = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
                }
                let appData = {}
                if (storeItemList && item.payment_type_id) {
                    const tmpData = storeItemList.find(el => el.id === item.payment_type_id)
                    appData = {
                        payment_type: tmpData.name,
                        payment_type_bn: tmpData.name_bn
                    }
                } else {
                    appData = {
                        payment_type: 'N/A',
                        payment_type_bn: 'N/A'
                    }
                }
                return Object.assign({}, item, orgData, appData)
            })
            this.applicationReportList = pumpOperatorData
            //   this.$store.dispatch('ExternalUserIrrigation/getSMCApplicationList', this.applicationReportList)
        },
        pdfExport () {
          const rowData = this.getPdfData()
          const labels = [
              { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
              { text: this.$t('globalTrans.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
              { text: this.$t('cardPayment.payment_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
              { text: this.$t('cardPayment.amount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
              { text: this.$t('cardPayment.payment_date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
              { text: this.$t('cardPayment.applicant_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
            ]
          rowData.unshift(labels)
          const reportTitle = this.$i18n.locale === 'en' ? 'Payment Collection Report' : 'অর্থ সংগ্রহের প্রতিবেদন'
           const columnWids = ['9%', '21%', '15%', '15%', '20%', '20%']
          ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
        },
        getPdfData () {
            const keys = [
                { key: 'serial_no' },
                { key: this.$i18n.locale === 'en' ? 'org_name' : 'org_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'payment_type' : 'payment_type_bn' },
                { key: this.$i18n.locale === 'en' ? 'amount' : 'amount' },
                { key: this.$i18n.locale === 'en' ? 'created_at' : 'created_at' },
                { key: this.$i18n.locale === 'en' ? 'far_name' : 'far_name_bn' }
            ]
          var serial = 0
          const listData = this.applicationReportList.map(item => {
            serial += 1
            const rowData = keys.map((keyItem, index) => {
                if (keyItem.key === 'serial_no') {
                    return { text: this.$n(serial) }
                }
              return { text: item[keyItem.key] }
            })
            return rowData
          })
          return listData
        }
    }
}
</script>
